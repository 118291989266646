import React, { useEffect, useState } from 'react';
import BVComponentsOld from '@bv/components-old';
import BVUtilsOld from '@bv/utils-old';
import * as C from './styles';
import InputField from '@login-old/components/InputField';
import { useForm } from 'react-hook-form';
import arrowToLeftAsset from '@login-old/assets/images/icons/arrow-to-left.svg';
import eyeAsset from '@login-old/assets/images/icons/eye.svg';
import eyeClosedAsset from '@login-old/assets/images/icons/eye-closed.svg';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import BVUtils from '@bv/utils';

const { Select, ButtonLoading } = BVComponentsOld;
const {
  api: { authApi },
  config: { PLATFORMS },
  cookiesHelper: { setCurrentUser },
} = BVUtilsOld;

type SecondStepLoginProps = {
  username: string;
  user: any;
  setUser: React.Dispatch<any>;
  setStep: React.Dispatch<React.SetStateAction<'firstStepLogin' | 'secondStepLogin' | 'forgotPassword' | 'emailSentForgotPassword'>>;
  isSSO: boolean;
  ssoSignIn: (username: string) => Promise<any>;
  sooInfo: { cloudPlataform: string; clientId: string };
};

const SecondStepLogin = ({ username, user, setUser, setStep, isSSO, ssoSignIn, sooInfo }: SecondStepLoginProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ mode: 'onTouched' });
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [companyId, setCompanyId] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCompanies(user?.companies || []);
    if (user?.companies?.length === 1) {
      setCompanyId(user.companies[0].id);
    }
  }, [user]);

  function onChangeCompany(company) {
    setCompanyId(company.id);
  }

  async function signIn(dataForm) {
    if (isSSO) {
      signInSSO(username);
      return;
    }

    const { password } = dataForm;
    try {
      const recaptchaToken = await executeRecaptcha('LOGIN');
      const { data } = await authApi.signIn(username, password, companyId, null, recaptchaToken);

      BVUtilsOld.cookiesHelper.setAccessToken(data.accessToken);
      BVUtilsOld.cookiesHelper.setCurrentUser(data.user);
      BVUtilsOld.cookiesHelper.setCompany(companyId);
      window.localStorage.setItem('token', JSON.stringify(data.accessToken));
      window.location.pathname = '/dashboard';
      // BVUtils.emitter.emit("@bv/login->authenticate", { ...data, companyId });
    } catch (err) {
      const message = err?.response?.data?.message || err?.message || 'Erro';
      BVUtils.toastHandler({ type: 'error', message });
    } finally {
    }
  }

  async function signInSSO(username: string) {
    try {
      const recaptchaToken = await executeRecaptcha('LOGIN');
      const account = await ssoSignIn(username);
      setLoading(true);

      const { data } = await authApi.signIn(username, null, companyId, account.accessToken, recaptchaToken);

      BVUtilsOld.cookiesHelper.setAccessToken(data.accessToken);
      BVUtilsOld.cookiesHelper.setCurrentUser(data.user);
      BVUtilsOld.cookiesHelper.setCompany(companyId);
      window.localStorage.setItem('token', JSON.stringify(data.accessToken));
      window.location.pathname = '/dashboard';
    } catch (err) {
      console.error(err);
      const message = err?.response?.data?.message || err?.message || 'Erro';
      if (err?.closedModal) {
        return;
      }
      BVUtils.toastHandler({ type: 'error', message });
    } finally {
      setLoading(false);
    }
  }

  return (
    <C.Container>
      <button className="spl-btn-back" onClick={() => setStep('firstStepLogin')}>
        <img src={arrowToLeftAsset} alt="arrow-to-left" width={24} height={24} />

        <span className="text-primary">Voltar</span>
      </button>
      <div>
        <C.DescriptionStyled>Bem-vindo(a) de volta,</C.DescriptionStyled>
        <C.NameStyled>{user?.name}</C.NameStyled>
      </div>
      <form onSubmit={handleSubmit(signIn)}>
        <div className="row">
          {!isSSO && (
            <div className="col-12">
              <C.FieldPassword showError={!!errors?.password?.type}>
                <InputField showError={!!errors?.password?.type} placeholder="Senha" label="Senha">
                  <input
                    autoFocus
                    type={showPassword ? 'text' : 'password'}
                    placeholder="🤫 Shhhhh..."
                    {...register('password', {
                      required: true,
                    })}
                  />
                </InputField>
                <button type="button" onClick={() => setShowPassword(!showPassword)} className="btn__eyes">
                  <img src={showPassword ? eyeAsset : eyeClosedAsset} width={24} height={24} />
                </button>
              </C.FieldPassword>
            </div>
          )}
          {user?.companies?.length > 1 && (
            <div className="col-12">
              <Select
                label="Empresa"
                isClearable={false}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                isSearchable={false}
                getOptionLabel={(company) => company.name}
                getOptionValue={(company) => company.id}
                onChange={onChangeCompany}
                options={companies}
              />
            </div>
          )}
        </div>
        <div className="col-12">
          <ButtonLoading
            type="submit"
            loading={isSubmitting || loading}
            disabled={isSubmitting || !(user.root || companyId || loading)}
            className="btn btn-primary w-100 flex items-center justify-center text-center">
            {/* {isSSO ? "Entrar com SSO" : "Entrar"} */}
            {isSSO ? 'Entrar com ' + PLATFORMS[sooInfo?.cloudPlataform] || sooInfo?.cloudPlataform : 'Entrar'}
          </ButtonLoading>
        </div>

        {!isSSO && (
          <div className="col-12 fs-forgot-password text-center">
            <button className="m-0 p-0" type={'button'} onClick={() => setStep('forgotPassword')}>
              <span className="text-primary">Esqueci minha senha</span>
            </button>
          </div>
        )}
      </form>
    </C.Container>
  );
};

export default SecondStepLogin;
